import isEmpty from '../utils/isEmpty';
import { ROLES } from '@/shared/constants';
import axios from 'axios';

const pathMap = {
  [ROLES.ROLE_ADMIN]: '/api/admin/fee',
  [ROLES.ROLE_SUPERVISOR]: '/api/supervisor/fee',
};

export default (role) => {
  const CONTROLLER_BASE_PATH = pathMap[role];

  return {
    createFee: ({ payoutLimit, ...payload }) =>
      axios.post(CONTROLLER_BASE_PATH, {
        ...payload,
        payoutLimit: !isEmpty(payoutLimit) ? +payoutLimit : null,
      }),
    updateFee: (payload) =>
      axios.put(CONTROLLER_BASE_PATH, {
        ...payload,
      }),
    getFees: (payload) =>
      axios.post(`${CONTROLLER_BASE_PATH}/find`, {
        ...payload,
      }),
  };
};
