<template>
  <d-view-card
    ref="view"
    :fetch-function="refillOrderController.getOrders"
    :filters="filters"
    :pagination="pagination"
    :filter-transformer="clearFilters"
    readonly-data
  >
    <template
      #default="{
        loading,
        find,
        selectedData,
        removeHandler,
        data,
        filtersEntity,
      }"
    >
      <div class="col-shrink row q-px-md q-pt-md">
        <q-card class="full-width">
          <q-card-section class="row q-col-gutter-sm">
            <q-select
              class="col-md-3 col-6"
              filled
              label="Status"
              clearable
              dense
              multiple
              use-chips
              emit-value
              map-options
              v-model="filtersEntity.status"
              :options="statusDictionary"
            />
            <q-input
              class="col-md-3 col-6"
              label="Payment Requisite Id"
              type="number"
              dense
              filled
              v-model.number="filtersEntity.paymentRequisiteId"
            />
            <q-input
              class="col-md-3 col-6"
              label="Merchant transaction Id"
              dense
              filled
              v-model="filtersEntity.merchantTransactionId"
            />
            <q-select
              v-if="isSupportOfficer"
              class="col-md-2 col-6"
              label="Currency"
              dense
              filled
              clearable
              emit-value
              map-options
              :options="currencies"
              option-label="code"
              option-value="code"
              v-model="filtersEntity.currency"
            />
            <q-input
              v-else
              class="col-md-2 col-6"
              label="Currency"
              dense
              filled
              clearable
              v-model="filtersEntity.currency"
            />
            <q-input
              class="col-md-3 col-6"
              label="Customer transaction Id"
              dense
              filled
              v-model="filtersEntity.customerTransactionId"
            />
            <q-input
              class="col-md-3 col-6"
              label="Customer Wallet Id"
              dense
              filled
              v-model="filtersEntity.customerWalletId"
            />
            <q-input
              :class="!isOperator ? 'col-md-3 col-6' : 'col-md-6 col-12'"
              label="Customer name"
              dense
              filled
              v-model="filtersEntity.customerName"
            />
            <d-user-select
              v-if="!isOperator"
              label="Operator"
              class="col-md-3 col-6"
              dense
              filled
              hide-bottom-space
              hide-hint
              v-model="filtersEntity.userId"
              emit-value
              map-options
              :options="specialOperators"
            />
            <q-input
              :class="!isOperatorOrAgent ? 'col-4' : 'col-6'"
              label="Merchant user ID"
              dense
              filled
              v-model="filtersEntity.merchantUserId"
            />
            <d-user-select
              v-if="!isOperatorOrAgent"
              v-model="filtersEntity.agentId"
              :options="agents"
              dense
              hide-bottom-space
              hide-hint
              label="Agent"
              filled
              emit-value
              map-options
              class="col-4"
            />
            <q-select
              :class="!isOperatorOrAgent ? 'col-4' : 'col-6'"
              emit-value
              map-options
              dense
              filled
              label="Refill method"
              option-label="name"
              option-value="id"
              :options="refillMethodTypesDictionary"
              v-model="filtersEntity.refillMethodTypeId"
            />
            <d-date-range
              ref="dateRange"
              class="col-md-6 col-12"
              :value="[
                filtersEntity.createdAt.from,
                filtersEntity.createdAt.to,
              ]"
              @input="setDateRange"
            />
            <d-integer-range
              class="col-md-6 col-12"
              :value="[filtersEntity.amount.from, filtersEntity.amount.to]"
              @input="setAmountRange"
            />
            <d-amount-control
              :value="filtersEntity.userRefillCount"
              @input="setUserRefillCount"
              class="col-md-5 col-12"
            />
            <q-input
              class="col-md col-12"
              label="Payload"
              dense
              filled
              v-model="filtersEntity.payload"
            />
          </q-card-section>
          <q-card-actions align="right">
            <q-btn
              v-if="!isSupportOfficer"
              outline
              color="primary"
              label="Report"
              @click="onReportHandler"
            />
            <q-btn color="primary" label="Apply" @click="find" />
          </q-card-actions>
        </q-card>
      </div>
      <div class="col-grow">
        <div class="q-pa-md">
          <d-refill-order-table
            :grid="$q.screen.lt.md"
            :items="data"
            :chip-status-config-builder="getConfigAtStatusValue"
            @on-status="setOrderStatus"
            @on-amount="openAmountPrompt"
            @on-customer-transaction-id="openCustomerTransactionPrompt"
            @on-update-requisite="updateRequisite"
          />
        </div>
      </div>
    </template>
  </d-view-card>
</template>

<script>
import { DEFAULT_FILTERS_STATE } from './config';
import { DAmountControl } from '@/features/amount-control';
import { DChangeAmountDialog } from '@/features/change-amount-dialog';
import { DDateRange } from '@/features/date-range-picker';
import { DIntegerRange } from '@/features/integer-range';
import { DRefillOrderTable } from '@/features/refill-orders-table';
import DUserSelect from '@/features/user-select';
import DViewCard from '@/layouts/view-card';
import {
  refillOrderController as apiController,
  refillMethodController,
} from '@/shared/api';
import {
  ROLES,
  ORDER_STATUS_DICTIONARY as statusDictionary,
} from '@/shared/constants';
import { default as refillAndPayoutOrdersMixin } from '@/shared/mixins/refillAndPayoutOrders';
import withReportLoader from '@/shared/mixins/withReportLoader';
import withStatuses from '@/shared/mixins/withStatuses';
import withAgentsDictionary from '@/shared/mixins/withUsersDictionary';
import { authUtils } from '@/shared/utils';
import { notify } from '@/shared/utils';
import { mapGetters } from 'vuex';

export default {
  components: {
    DViewCard,
    DRefillOrderTable,
    DDateRange,
    DIntegerRange,
    DAmountControl,
    DUserSelect,
  },
  mixins: [
    withReportLoader,
    withStatuses,
    refillAndPayoutOrdersMixin({
      apiController,
    }),
    withAgentsDictionary,
  ],
  data: () => ({
    filters: { ...DEFAULT_FILTERS_STATE },
    pagination: {
      lastId: 0,
      maxResults: 512,
      descending: true,
    },
    statusDictionary,
    refillMethodTypesDictionary: [],
    refillOrderController: apiController(authUtils.getRoleByHierarchy()),
  }),
  async mounted() {
    try {
      this.$refs.view.setLoading(true);
      await this.fetchRefillMethodTypes();

      if (!this.isOperator) {
        await this.loadAgents();
      }
    } catch (e) {
      notify.error(e.message);
    } finally {
      this.$refs.view.setLoading(false);
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'GET_USER_CURRENCIES',
    }),
    isSupportOfficer: () =>
      authUtils.getRoleByHierarchy() === ROLES.ROLE_SUPPORT_OFFICER,
  },
  methods: {
    setDateRange(value) {
      this.filters.createdAt.from = value[0];
      this.filters.createdAt.to = value[1];
    },
    setAmountRange(value) {
      this.filters.amount.from = value[0];
      this.filters.amount.to = value[1];
    },
    setUserRefillCount({ value, type }) {
      this.filters.userRefillCount.value = +value;
      this.filters.userRefillCount.type = type?.value;
    },
    openCustomerTransactionPrompt({ id, customerTransactionId }) {
      this.$q
        .dialog({
          title: 'Change customer transaction id',
          message: 'Enter new id',
          prompt: {
            model: customerTransactionId,
            type: 'text',
            hint: 'Required field*',
            isValid: (val) => !!val || 'Value must not be empty',
          },
          cancel: true,
          persistent: true,
        })
        .onOk((newValue) => {
          this.changeOrderWrapper(
            this.refillOrderController.changeOrderCustomerTransactionId.bind(
              null,
              {
                id,
                customerTransactionId: newValue,
              }
            )
          );
        });
    },
    openAmountPrompt({ id, amount, maxLimit }) {
      this.$q
        .dialog({
          component: DChangeAmountDialog,
          parent: this,
          value: +amount,
          rules: [
            (val) => val > 0 || 'Value must not be less than 0',
            (val) => {
              if (!Number.isFinite(val) || val > maxLimit) {
                return `Amount max limit: ${maxLimit}`;
              }

              return true;
            },
            (val) => {
              if (!this.isOperatorOrAgent) {
                return true;
              }

              if (Math.abs(val - amount) > amount) {
                return 'Delta of amounts should be less or equal to 100%';
              }

              return true;
            },
          ],
        })
        .onOk((newValue) =>
          this.changeOrderWrapper(
            this.refillOrderController.changeOrderAmount.bind(null, {
              id,
              amount: newValue,
            })
          )
        );
    },
    async updateRequisite({ id, paymentRequisiteId }) {
      await this.changeOrderWrapper(
        this.refillOrderController.changeOrderRequisite.bind(null, {
          id,
          paymentRequisiteId,
        })
      );
    },
    async changeOrderWrapper(callback) {
      try {
        this.$refs.view.setLoading(true);
        await callback();
        await this.$refs.view.find();
        notify.success();
      } catch (e) {
        notify.error();
      } finally {
        this.$refs.view.setLoading(false);
      }
    },
    onReportHandler() {
      const isValid = this.$refs.dateRange.validate();
      if (!isValid) return;

      this.callPromptForEmail().onOk(async (email) => {
        try {
          await this.refillOrderController.applyReport({
            filter: this.clearFilters(this.filters),
            email,
          });
          notify.success('Report request sent successfully');
        } catch (e) {
          notify.error('Something went wrong while sending a report request.');
        }
      });
    },
    async fetchRefillMethodTypes() {
      const { data } = await refillMethodController(
        authUtils.getRoleByHierarchy()
      ).getRefillMethods();

      this.refillMethodTypesDictionary = data;
    },
  },
};
</script>
