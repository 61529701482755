<template>
  <div class="row q-gutter-sm">
    <div class="col-3 column q-gutter-sm">
      <q-input
        outlined
        class="col-shrink"
        readonly
        dense
        label="Merchant name"
        :value="value.row.merchant.name"
      />
      <q-input
        outlined
        class="col-shrink"
        readonly
        dense
        label="Customer name"
        :value="value.row.customerName"
      />
      <q-input
        outlined
        class="col-shrink"
        dense
        readonly
        label="Customer wallet id"
        :value="value.row.customerWalletId"
      />
      <q-input
        outlined
        class="col-shrink"
        dense
        readonly
        label="Currency"
        :value="value.row.currency"
      />
    </div>
    <div class="col-4 column q-gutter-sm">
      <q-input
        outlined
        class="col-shrink"
        dense
        readonly
        label="Created at"
        :value="value.row.createdAt"
      />
      <q-input
        outlined
        class="col-shrink"
        dense
        readonly
        label="Updated at"
        :value="value.row.updatedAt"
      />
      <div>
        <d-requisite-btn
          class="full-width"
          :as-text="checkDisabledAgentSwitchByUserRole(value.row)"
          :is-disable="checkDisabledAgentSwitchByUserRole(value.row)"
          @click="
            requisiteHandler(
              value.row.paymentRequisite.refillMethodTypeId,
              value.row.id
            )
          "
        >
          {{ value.row.paymentRequisite.name }} <br />
          {{ value.row.agentName }}
        </d-requisite-btn>

        <d-requisite-select
          v-if="currentRefillOrderId === value.row.id"
          :value="value.row"
          :options="currentRequisites"
          :dense="false"
          outlined
          class="q-mt-sm"
          @input="$emit('on-update-requisite', $event)"
        />
      </div>
    </div>
    <div class="col-4 column q-gutter-sm">
      <q-input
        v-if="value.row.operator"
        outlined
        class="col-shrink"
        dense
        readonly
        label="Changed by"
        :value="value.row.operator.username"
      />
      <d-object-view
        v-if="Object.keys(value.row.payload).length"
        label="Payload"
        :value="value.row.payload"
      />
      <d-object-view
        v-if="Object.keys(value.row.paymentRequisite.payload).length"
        label="Payment value"
        :value="value.row.paymentRequisite.payload"
      />
    </div>
  </div>
</template>

<script>
import DRequisiteBtn from './requisite-btn';
import DRequisiteSelect from './requisite-select';
import withAgentSwitchDisable from './withAgentSwitchDisable';
import withRequisiteLoader from './withRequisiteLoader';
import { DObjectView } from '@/features/object-view';
import { STATUSES } from '@/shared/constants';

export default {
  components: { DRequisiteSelect, DRequisiteBtn, DObjectView },
  mixins: [withRequisiteLoader, withAgentSwitchDisable],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    statuses: Object.freeze(STATUSES),
  }),
};
</script>
