import { ROLES, STATUSES } from '@/shared/constants';
import { authUtils } from '@/shared/utils';

export default {
  methods: {
    checkDisabledAgentSwitchByUserRole(item) {
      if (
        item?.status === STATUSES.APPROVED &&
        authUtils.getRoleByHierarchy() === ROLES.ROLE_SUPERVISOR
      ) {
        return true;
      }

      return !authUtils.checkRoles([
        ROLES.ROLE_SUPERVISOR,
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPPORT_OFFICER,
      ]);
    },
  },
};
